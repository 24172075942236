import logo from './logo.svg';
import './App.css';
import { render, Printer, Text } from 'react-thermal-printer';

function App() {

  const print = async () => {
    const data = await render(
      <Printer type="epson">
        <Text>Hello World</Text>
      </Printer>
    );
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });

    const writer = port.writable?.getWriter();
    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  }
  return (
    <div className="App">
      <button onClick={() => print()}>
        Print
      </button>
    </div>
  );
}

export default App;
